<template>
  <div style="display: flex">
    <slot/>
  </div>
</template>
<script>
export default {
  name: "BoxFlex"
}
</script>
<style scoped>
</style>
