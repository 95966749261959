<template>
  <div style="display: flex; flex-direction: column;">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BoxCol"
}
</script>

<style scoped>

</style>