<template>
  <div style="display: flex; flex-direction: row;width:100%">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BoxRow"
}
</script>

<style scoped>

</style>